import { createStore } from 'vuex'
import AuthStore from '@/modules/auth/store'
import CustomerStore from '@/modules/customer/store'
import AddressStore from '@/modules/address/store'
import CartStore from "@/modules/cart/store"
import SaleFlowStore from "@/modules/saleFlow/store";
import PartnerService from "@/services/partner/partner.service"
import CookieService from "@/services/policy/cookie.service"
import GoogleService from "@/services/tracking/google.service"
import mcafeeStore from "@/modules/mcafee/store"

const store = createStore({
    namespaced: true,
    state: {
        isCookiePolicyAccepted: false,
    },
    mutations: {
        setCookiePolicyStatus(state, status) {
            state.isCookiePolicyAccepted = status;
        }
    },
    actions: {
        setPartner (context, partner) {
            if (!partner) {
                return
            }

            PartnerService.setActivePartner(partner)
        },
        startPartnerTracking ({ getters }) {
            const analyticTag = getters.getPartnerConfiguration?.analyticTag || null
            const analyticCategory = getters.getPartnerConfiguration?.analyticCategory || null

            if (!analyticTag, analyticCategory) {
                return
            }

            GoogleService.startTracking(analyticTag, analyticCategory)
        },
        acceptCookiePolicy({ commit }) {
            return new Promise((resolve) => {
              CookieService.setAcceptedCookie();
              commit('setCookiePolicyStatus', true);
              resolve();
            });
        }
    },
    getters: {
        getPartner: () => PartnerService.getActivePartner() || PartnerService.getDefaultPartner(),
        getPartnerConfiguration: (state, getters) => PartnerService.getPartnerConfiguration(getters.getPartner),
        getCookiesPolicyAccepted: (state) => state.isCookiePolicyAccepted,
        getPhoneNumber: () => process.env.VUE_APP_WHATSAPP_PHONE_NUMBER   
    },
    modules: {
        auth: AuthStore,
        customer: CustomerStore,
        address: AddressStore,
        cart: CartStore,
        saleFlow: SaleFlowStore,
        mcafee: mcafeeStore
    }
})
export default store;