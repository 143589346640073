import ClaroService from '../../../services/claro.service'
import Swal from "sweetalert2";

const namespaced = true

const state = {
  showModalAuthenticate: false,
  link: null,
  confirmCallback: null,
  requireShowModalAuthenticate: false,
}

const mutations = {
  SET_SHOW_MODAL(state, show) {
    state.showModalAuthenticate = show
  },
  SET_LINK (state, link) {
    state.link = link
  },
  SET_CONFIRM_CALLBACK (state, callback) {
    state.confirmCallback = callback
  },
  SET_REQUIRE_SHOW_MODAL_AUTHENTICATE (state, requireShow) {
    state.requireShowModalAuthenticate = requireShow
  },
}

const actions = {
  async openModal ({ commit }, confirmCallback) {
    commit('SET_SHOW_MODAL', true)
    commit('SET_CONFIRM_CALLBACK', confirmCallback || null)
  },
  async closeModal ({ commit }) {
    commit('SET_SHOW_MODAL', false)
  },
  async clearRequireOpenModal ({ commit }) {
    commit('SET_REQUIRE_SHOW_MODAL_AUTHENTICATE', false)
  },
  async copyLinkToTransferArea ({ getters }) {
    return navigator.clipboard.writeText(getters.getLink)
  },
  async generateLink ({ commit, rootGetters }) {
    return ClaroService.generateAuthenticateLink(rootGetters['saleFlow/getSaleOfService']?.id)
      .then(response => {
        commit('SET_LINK', response?.link || null)
      })
  },
  async setIntentToOpenModalByApiMessage ({ commit }, apiMessage) {
    const message = apiMessage?.toLowerCase() || ''

    if (message?.search('claro autentica com erro') === -1) {

      Swal.fire({
        title: 'Ops ... Houve um erro, por favor, reinicie o processo de compra. Agradecemos pela sua compreensão!',
        type: 'warning',
        confirmButtonText: 'Ok',
      });
      return

    }
    
    if (message?.search('aguardando claro autentica') >= 0) {
      commit('SET_REQUIRE_SHOW_MODAL_AUTHENTICATE', true)
    }
  },
}

const getters = {
  getShowModal: (state) => state.showModalAuthenticate,
  getLink: (state) => state.link,
  getConfirmCallback: (state) => state.confirmCallback instanceof Function ? state.confirmCallback : () => {},
  isValidLink: (state) => {
    try {
      return Boolean(new URL(state.link))
    } catch (error) {
      return false
    }
  },
  requireShowModalAuthenticate: (state) => state.requireShowModalAuthenticate || false,
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
