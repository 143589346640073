import Api from '@/services/api'
import AuthService from '@/modules/auth/services/auth.service'

export  function myOrders (currentPage) {
    return Api.post(`api/sale-of-services/list-customer`, {currentPage }), {
        headers: {
            AuthorizationCustomer: AuthService.getCustomerToken()
        }
    }
}
export default {
    myOrders  
}