import ProductTypeGroupConstants from '@/constants/sale/productTypeGroup.constants'

const checkGroupHasProductTypeIdsDecoded = function (productTypeIdsDecoded, group) {
  return productTypeIdsDecoded?.some((productTypeDecoded) => ProductTypeGroupConstants[group]?.includes(productTypeDecoded?.id) || false) || false
}

const matchAllCartProductsWithSaleFlowProductsCatalog = function (cartProducts, saleFlowProductsCatalog) {
  const cartItemsNotFound = []

  cartProducts.forEach(cartItem => {
    let foundProduct = false

    saleFlowProductsCatalog.every(productCatalog => {
      if (cartItem?.product?.id === productCatalog?.product?.id) {
        foundProduct = true
        return false
      }

      return true
    })

    if (foundProduct === false) {
      cartItemsNotFound.push(cartItem)
    }
  })


  return { cartItemsNotFound }
}

const getProductsToAddAndToRemoveByCartAndCheckoutProducts = function (cartProducts, checkoutProducts ) {
  const productsToAdd = []
  const productsToRemove = []

  // Check Cart into CheckoutProducts to add
  cartProducts.forEach(cartItem => {
    let foundProduct = false

    checkoutProducts.every(checkoutProduct => {
      if (cartItem?.product?.id === checkoutProduct?.productId && cartItem?.promotion?.id === checkoutProduct?.productPromotionId) {
        foundProduct = true
        return false
      }

      return true
    })

    if (foundProduct === false) {
      productsToAdd.push(cartItem)
    }
  })

  // Check CheckoutProducts into Cart to remove
  checkoutProducts.forEach(checkoutProduct => {
    let foundCheckoutProduct = false

    cartProducts.every(cartItem => {
      if (cartItem?.product?.id === checkoutProduct?.productId && cartItem?.promotion?.id === checkoutProduct?.productPromotionId) {
        foundCheckoutProduct = true
        return false
      }

      return true
    })

    if (foundCheckoutProduct === false) {
      productsToRemove.push(checkoutProduct)
    }
  })

  return { productsToAdd, productsToRemove }
}

export default {
  checkGroupHasProductTypeIdsDecoded,
  matchAllCartProductsWithSaleFlowProductsCatalog,
  getProductsToAddAndToRemoveByCartAndCheckoutProducts,
}