export const routes = [
  {
    path: 'hp-mcafee',
    name: 'HomeHpMcafee',
    component: () => import(/* webpackChunkName: "landingPageHpMcafeeHome" */ '@/modules/landingPages/hpMcafee/Home')
  },
  {
    path: 'rigstar',
    name: 'HomeMcafeeRegister',
    component: () => import(/* webpackChunkName: "landingPageMcafeeHome" */ '@/modules/landingPages/mcafee/Home')
  },
  {
    path: 'mcafee',
    name: 'HomeMcafee',
    component: () => import(/* webpackChunkName: "landingPageMcafeeHome" */ '@/modules/landingPages/mcafee/Home')
  },
  {
    path: 'clarotv',
    name: 'HomeClaroTv',
    component: () => import(/* webpackChunkName: "landingPageClaroTvHome" */ '@/modules/landingPages/claro/tv/Home')
  },
  {
    path: 'clarofibra',
    name: 'HomeClaroFibra',
    component: () => import(/* webpackChunkName: "landingPageClaroTvHome" */ '@/modules/landingPages/claro/fibra/Home')
  },
  {
    path: 'esqueci-senha',
    name: 'EsqueciSenha',
    component: () => import(/* webpackChunkName: "landingPageClaroTvHome" */ '@/components/Login/ForgotPassword')
  },
  {
    path: 'alterar-senha',
    name: 'AlterarSenha',
    component: () => import(/* webpackChunkName: "landingPageClaroTvHome" */ '@/components/Login/ChangePassword')
  },
  {
    path: 'idscan',
    name: 'HomePageDarkWeb',
    component: () => import(/* webpackChunkName: "landingPageHomePageDarkWeb" */ '@/modules/landingPages/mcafeeDarkWeb/Home')
  },
  {
    path: 'infopromohp',
    name: 'HomePageStatic',
    component: () => import(/* webpackChunkName: "landingPageHomeMcafeeStatic" */ '@/modules/landingPages/mcafeeStatic/Home')
  },
  {
    path: ':pathMatch(.*)?',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "landingPageNotFound" */ '@/modules/landingPages/404/NotFound')
  },
]
