import ClaroService from '@/modules/saleFlow/services/claro.service'

const namespaced = true

const state = {
  msisdnList: [],
}

const mutations = {
  SET_MSISDN_LIST (state, msisdnList) {
    state.msisdnList = msisdnList
  }
}

const actions = {
  fetchMsisdnList ({ commit, rootGetters }, ddd) {
    return ClaroService.listMsisdnForControleBoleto(rootGetters['saleFlow/getSaleOfService']?.id, ddd)
      .then((response) => {
        commit('SET_MSISDN_LIST', response?.numbers)
      })
  }
}

const getters = {
  getMsisdnList: (state) => state.msisdnList || []
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
