import Jwt from "@/services/jwt"
import ProductService from "@/modules/saleFlow/services/product.service"
import CartService from "@/modules/cart/services/cart.service"
import OperatorsConstants from "@/constants/sale/operators.constants"


const namespaced = true

const state = {
  cart: [],
  isShowingCart: false,
  isShowingRequest: false,
  calculatedTotalPrice: 0,
  authenticatorRequired: false
}

const mutations = {
  ADD_PRODUCT_CART (state, {
    product,
    promotion,
    productResidentialPointAdditionalAmount,
    productResidentialPointAdditionalType
  }) {
    state.cart.push({
      product,
      promotion,
      productResidentialPointAdditionalAmount,
      productResidentialPointAdditionalType
    })
  },
  REMOVE_PRODUCT_CART (state, { id }) {
    state.cart = state.cart.filter((cartItem) => cartItem?.product?.id !== id)
  },
  CLEAR_CART (state ) {
    state.cart = []
  },
  SET_CART_ITEMS (state, items) {
    state.cart = items
  },
  SET_SHOW_CART (state, isShowingCart) {
    state.isShowingCart = isShowingCart
  },
  SET_SHOW_REQUEST(state, isShowingRequest) {
    state.isShowingRequest = isShowingRequest
  },
  SET_AUTHENTICATOR_REQUIRED(state, authenticatorRequired){
    state.authenticatorRequired = authenticatorRequired
  }
}

const actions = {
  async addProductCart({ commit, getters, dispatch }, { product, promotion, productResidentialPointAdditionalType, productResidentialPointAdditionalAmount })  {
    const productTypeIdDecoded = Jwt.parseJwt(product?.productTypeId);
    const productsToRemove = [];

    // Remove Another MCAFEE products in cart
    if (
      ProductService.checkGroupHasProductTypeIdsDecoded(
        [productTypeIdDecoded],
        OperatorsConstants.MCAFEE
      )
    ) {
      getters.getCartItemsForMcafee?.forEach((cartItem) =>
        productsToRemove.push(cartItem?.product)
      );
    }
    // Remove Another CLARO products in cart
    if(
      ProductService.checkGroupHasProductTypeIdsDecoded(
        [productTypeIdDecoded],
        OperatorsConstants.CLARO
      )
    ) {
      getters.getCartItemsForClaro?.forEach((cartItem) =>
        productsToRemove.push(cartItem?.product)
      );
    }
    // Remove all products in list to remove
    if (productsToRemove.length > 0) {
      productsToRemove.forEach((productToRemove) =>
        dispatch("removeProductCart", productToRemove)
      );
    }

    await commit("ADD_PRODUCT_CART", { product, promotion, productResidentialPointAdditionalType, productResidentialPointAdditionalAmount });

    CartService.setCartStorage(getters.getCartItems);
  },
  async removeProductCart({ commit, getters }, product) {
    await commit("REMOVE_PRODUCT_CART", product);

    CartService.setCartStorage(getters.getCartItems);
    
  },
  async clearCart({ commit, getters }, product) {
    await commit("CLEAR_CART", product);

    CartService.setCartStorage(getters.getCartItems);
  },
  async restoreProductsCart({ commit }) {
    const cart = CartService.getCartStorage();

    if (Array.isArray(cart) === false || cart.length === 0) {
      return Promise.resolve();
    }

    return commit("SET_CART_ITEMS", cart);
  },
  async openCart({ commit }) {
    commit("SET_SHOW_CART", true);
  },
  async closeCart({ commit }) {
    commit("SET_SHOW_CART", false);
  },
  async openRequest({ commit }) {
    commit("SET_SHOW_REQUEST", true);
  },
  async closeRequest({ commit }) {
    commit("SET_SHOW_REQUEST", false);
  },
  async authenticatorRequired({ commit }) {
    commit("SET_AUTHENTICATOR_REQUIRED", true);
  },
};

const getters = {
  isShowingRequest: (state) => state.isShowingRequest,
  isShowingCart: (state) => state.isShowingCart,
  isCartEmpty: (state, getters) => getters.getCartItems.length !== 0,
  getCartItems: (state) => state.cart || [],
  getCartLength: (state) => state.cart?.length || 0 ,
  hasProductInCart: (state, getters) => (product) => getters.getCartItems.some((cartItem) => cartItem?.product?.id === product?.id),
  getCartItemsForMcafee: (state, getters) => getters.getCartItems.filter((cartItem) => ProductService.checkGroupHasProductTypeIdsDecoded([Jwt.parseJwt(cartItem?.product?.productTypeId)], OperatorsConstants.MCAFEE)),
  getCartItemsForClaro: (state, getters) => getters.getCartItems.filter((cartItem) => ProductService.checkGroupHasProductTypeIdsDecoded([Jwt.parseJwt(cartItem?.product?.productTypeId)], OperatorsConstants.CLARO)),
  getCartDetails: (state, getters) => {
    const items = []
    let total = 0
    let totalPoints = 0
    let totalPrice = 0
    let newTotalPrice = 0

    for (const index in getters.getCartItems) {
      const item = getters.getCartItems[index]
      const promotionPrice = item?.promotion?.price?.price || 0

      const pointAdditionalPrice = item?.productResidentialPointAdditionalType?.price || 0
      const pointAdditionalAmount = item?.productResidentialPointAdditionalAmount || 0
      const pointsAdditionalPrice = pointAdditionalPrice * pointAdditionalAmount
      const pointsTotalPrice = pointsAdditionalPrice + promotionPrice
      items.push({
        item: {
          product: item?.product || null,
          promotion: item?.promotion || null,
          productResidentialPointAdditionalAmount: item?.productResidentialPointAdditionalAmount || null,
          productResidentialPointAdditionalType: item?.productResidentialPointAdditionalType || null
        },
        price: promotionPrice,
        itemPoint: pointsAdditionalPrice,
        totalPrice: pointsTotalPrice
        
      })

      total += promotionPrice,
      totalPoints += pointsAdditionalPrice
      totalPrice = promotionPrice + pointsAdditionalPrice
      newTotalPrice = promotionPrice + pointsAdditionalPrice
    }

    return { items, total , totalPoints, totalPrice, newTotalPrice }
  },

  getCartTotalPrice: (state, getters) => {
  const savedData = getters.getCartDetails.totalPrice;
  return savedData ? savedData : 0;
},
  getAuthenticatorRequired: (state, getters) => {
    return getters.getCartItems.some(item => item?.product?.requireClaroAuthenticate === true);
  }
}


export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}