import AddressService from '../services/address.service'

const namespaced = true

const state = {
    addresses: [],
    currentAddress: null,
    showingRegionalizationModal: false,
    regionalizationModalCallback: null,
    showClaroFields: false,
}

const mutations = {
    SET_ADDRESSES (state, addresses) {
        state.addresses = addresses
    },
    SET_EMPTY_ADDRESSES (state) {
        state.addresses = []
    },
    SET_CURRENT_ADDRESS (state, address) {
        state.currentAddress = address
        AddressService.setCurrentAddress(address)
    },
    SET_REGIONALIZATION_MODAL_CALLBACK (state, regionalizationModalCallback) {
        state.regionalizationModalCallback = regionalizationModalCallback
    },
    SET_SHOW_REGIONALIZATION_MODAL (state, isShowing) {
        state.showingRegionalizationModal = isShowing
    },
    SET_SHOW_CLARO_FIELDS (state, isShowing) {
        state.showClaroFields = isShowing
    }
}

const actions = {
    async findByPostalCode ({ commit }, postalCode) {
        return AddressService.findByPostalCode(postalCode)
            .then(response => {
                commit('SET_ADDRESSES', response?.addresses)
            }).
            catch(response => {
                if (response.status === 404) {
                    commit('SET_EMPTY_ADDRESSES')

                    return Promise.resolve()
                }

                return Promise.reject(response?.data)
            })
    },
    async setCurrentAddress ({ commit }, address) {
        commit('SET_CURRENT_ADDRESS', address)
    },
    async setRegionalizationModalCallback ({ commit }, callback) {
        commit('SET_REGIONALIZATION_MODAL_CALLBACK', callback)
    },
    async openRegionalizationModal ({ commit }) {
        commit('SET_SHOW_REGIONALIZATION_MODAL', true)
    },
    async closeRegionalizationModal ({ commit }) {
        commit('SET_SHOW_REGIONALIZATION_MODAL', false)
    },
    async setShowClaroFields ({ commit }, isShowing) {
        commit('SET_SHOW_CLARO_FIELDS', isShowing = true)
    },
    async setNoClaroFields ({ commit }, isShowing) {
        commit('SET_SHOW_CLARO_FIELDS', isShowing = false)
    },
    async clearCurrentAddress ({ commit }, address) {
        commit('SET_EMPTY_ADDRESSES', address)
    },
}

const getters = {
    getAddresses: (state) => state.addresses[0],
    getCurrentAddress: (state) => state.currentAddress?.id ? state.currentAddress : AddressService.getCurrentAddress(),
    getRegionalizationModalCallback: (state) => state.regionalizationModalCallback instanceof Function ? state.regionalizationModalCallback : () => {},
    getShowingRegionalizationModal: (state) => state.showingRegionalizationModal,
    getShowClaroFields: (state) => state.showClaroFields,
}

const addressModule = {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}

export function registerModule (store) {
    store.registerModule('address', addressModule)
}

export function unregisterModule (store) {
    store.unregisterModule('address', store)
}

export default addressModule
