export const routes = [
    {
        path: 'sale-flow',
        name: 'SaleFLow',
        component: () => import(/* webpackChunkName: "saleFlowPage" */ '@/modules/saleFlow/SaleFlow'),
        redirect: to => ({
            path: `${to?.params?.partner}/sale-flow/cart-step-customer`,
        }),
        children: [

            {
                path: 'cart-step-product-schedule',
                name: 'ProductStep',
                component: () => import(/* webpackChunkName: "saleFlowPage" */ '@/modules/saleFlow/schedule/Schedule'),
            },
            {
                path: 'cart-step-customer',
                name: 'CustomerStep',
                component: () => import(/* webpackChunkName: "saleFlowPage" */ '@/modules/saleFlow/customer/Customer'),
            },
            {
                path: 'cart-step-payment',
                name: 'PaymentStep',
                component: () => import(/* webpackChunkName: "saleFlowPage" */ '@/modules/saleFlow/payment/Payment'),
            },
            {
                path: 'cart-step-order',
                name: 'OrderStep',
                component: () => import(/* webpackChunkName: "saleFlowPage" */ '@/modules/saleFlow/order/Order'),
                meta: {
                    title: 'Pedido Concluído'
                }
            }
        ],
    },
]
