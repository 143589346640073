<template>
  <img :src="iconLink" />
</template>

<script>
  export default {
    name: "Icon",
    props: {
      name: {
        required: true,
      }
    },
    computed: {
      iconLink() {
        return require(`@/assets/icons/${this.name}.svg`)
      }
    }
  }
</script>
