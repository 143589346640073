import axios from 'axios'
import configuration from '@/services/configuration'
import setRequestInterceptors from '@/services/api/requestInterceptors'
import setResponseInterceptors from '@/services/api/responseInterceptors'

const api = axios.create({
    baseURL: configuration.api.url,
    responseType: 'json'
})

if (process.env.NODE_ENV !== 'test') {
    api.defaults.timeout = 600000 // 10 minutes for timeout
}

if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}


setRequestInterceptors(api)
setResponseInterceptors(api)

export default api
