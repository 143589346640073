import CustomerService from '../services/customer.service'

const namespaced = true

const state = {
    customer: {
        id: null,
        cpf: null,
        email: null,
        name: null,
        rg: null,
        issuingBody: null,
        uf: null,
        dateOfIssue: null,
        birthdate: null,
        motherName: null,
        gender: null,
        phone1: null,
        phone2: null,
        updatedAt: null,
       
    },
    customerAddress: {
        id: null,
        addressId: null,
        customerId:null,
        number: null,
        complement: null,
    },


   
 

  
}

const mutations = {
    SET_CUSTOMER (state, { id, cpf, email, name, rg, issuingBody, uf, dateOfIssue, birthdate, motherName, gender, phone1, phone2, updatedAt }) {
        state.customer = {
            id,
            cpf,
            email,
            name,
            rg,
            issuingBody,
            uf,
            dateOfIssue: dateOfIssue?.date || dateOfIssue,
            birthdate,
            motherName,
            gender: gender?.current || gender,
            phone1,
            phone2,
            updatedAt
        }
    },
    SET_CUSTOMER_SENSITIVE_DATA (state, { birthdate, motherName }) {
        state.customer.birthdate = birthdate
        state.customer.motherName = motherName
    },
    SET_EMPTY_CUSTOMER (state, { cpf }) {
        state.customer = {
            id: null,
            cpf: cpf || null,
            email: null,
            name: null,
            rg: null,
            issuingBody: null,
            uf: null,
            dateOfIssue: null,
            birthdate: null,
            motherName: null,
            gender: null,
            phone1: null,
            phone2: null,
            updatedAt: null,
        }
    },
    SET_CUSTOMER_ADDRESS (state, { id, addressId, customerId, number, complement }) {
        state.customerAddress = {
            id,
            addressId,
            customerId,
            number,
            complement,
            
        }
    },
}

const actions = {
    async findByCpf ({ commit }, cpf) {
        return CustomerService.findByCpf(cpf)
            .then(response => {
                commit ('SET_CUSTOMER', response?.customer || {})

                return Promise.resolve()
            })
            .catch(response => {
                if (response.status === 404) {
                    commit('SET_EMPTY_CUSTOMER', { cpf: cpf })

                    return Promise.resolve()
                }

                return Promise.reject(response?.data)
            })
    },
    async save ({ commit }, { id, cpf, email, name, rg, issuingBody, uf, dateOfIssue, birthdate, motherName, gender, phone, phone2 }) {
        return CustomerService.saveCustomer({
            id: id || null,
            cpf,
            email,
            name,
            rg,
            issuingBody,
            uf,
            dateOfIssue,
            birthdate,
            motherName,
            gender,
            phone,
            phone2
        })
            .then(response => {
                commit ('SET_CUSTOMER', response?.customer || {})
                commit ('SET_CUSTOMER_SENSITIVE_DATA', response?.customer || {})
            })
    },
    async associateAddress ({ commit }, { customerId, addressId, number, complement }) {
        return CustomerService.associateAddress(
            {
                customerId
            },
            {
                addressId,
                number,
                complement
            }
        ).then(response => {
            commit('SET_CUSTOMER_ADDRESS', response?.customerAddress || {})
        })
    }
}

const getters = {
    getCustomer: (state) => state.customer,
    getCustomerAddress: (state) => state.customerAddress,
}

const customerModule = {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}

export function registerModule (store) {
    store.registerModule('customer', customerModule)
}

export function unregisterModule (store) {
    store.unregisterModule('customer', store)
}

export default customerModule
