import AuthenticateStore from './authenticate'
import PrePagoStore from './prepago'
import ControleBoletoStore from './controleboleto'
import ClaroService from '@/modules/saleFlow/services/claro.service'

const namespaced = true

const state = () => ({
  dddList: [],
  mobileInvoiceDueDateList: [],
  residentialInvoiceDueDateList: [],
  customerMsisdnListForMigration: [],
})

const mutations = {
  SET_DDD_LIST (state, dddList) {
    state.dddList = dddList
  },
  SET_MOBILE_INVOICE_DUE_DATE_LIST (state, mobileInvoiceDueDateList) {
    state.mobileInvoiceDueDateList = mobileInvoiceDueDateList
  },
  SET_RESIDENTIAL_INVOICE_DUE_DATE_LIST (state, residentialInvoiceDueDateList) {
    state.residentialInvoiceDueDateList = residentialInvoiceDueDateList
  },
  SET_CUSTOMER_MSISDN_LIST_FOR_MIGRATION (state, customerMsisdnListForMigration) {
    state.customerMsisdnListForMigration = customerMsisdnListForMigration
  },
}

const actions = {
  fetchDDDList ({ commit, rootGetters }) {
    return ClaroService.listRegionalDDD(rootGetters['saleFlow/getSaleOfService']?.id)
      .then((response) => {
        commit('SET_DDD_LIST', response?.areaCodeCollection?.items)
      })
  },
  fetchMobileInvoiceDueDateList ({ commit, rootGetters }) {
    return ClaroService.listMobileInvoiceDueDate(rootGetters['saleFlow/getSaleOfService']?.id)
      .then((response) => {
        commit('SET_MOBILE_INVOICE_DUE_DATE_LIST', response?.invoiceDueDateCollection?.items)
      })
  },
  fetchResidentialInvoiceDueDateList ({ commit }) {
    return ClaroService.listResidentialInvoiceDueDate()
      .then((response) => {
        commit('SET_RESIDENTIAL_INVOICE_DUE_DATE_LIST', response?.checkoutResidentialInvoiceDueDateCollection?.items)
      })
  },
  fetchCustomerMsisdnListForMigration ({ commit, rootGetters }, ddd) {
    return ClaroService.listCustomerMsisdnForMigration(rootGetters['saleFlow/getSaleOfService']?.id, ddd)
      .then((response) => {
        commit('SET_CUSTOMER_MSISDN_LIST_FOR_MIGRATION', response || [])
      })
  },
}

const getters = {
  getDDDList: (state) => state.dddList || [],
  getMobileInvoiceDueDateList: (state) => state.mobileInvoiceDueDateList || [],
  getResidentialInvoiceDueDateList: (state) => state.residentialInvoiceDueDateList || [],
  getCustomerMsisdnListForMigration: (state) => state.customerMsisdnListForMigration || [],
}

const modules = {
  authenticate: AuthenticateStore,
  prePago: PrePagoStore,
  controleBoleto: ControleBoletoStore,
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules,
}
