import Configuration from "@/services/configuration"

const namespaced = true

const state = {
  pointOfSale: {
    id: null,
  },
}

const mutations = {
  SET_POINT_OF_SALE (state, pointOfSale) {
    state.pointOfSale = pointOfSale
  },
}

const actions = {
  async setCurrent ({ commit }) {
    commit('SET_POINT_OF_SALE', {
      id: Configuration?.api?.pointOfSale?.id
    })
  },
  async clearCurrent({ commit }) {
    commit('SET_POINT_OF_SALE', null)

    return Promise.resolve()
  }
}

const getters = {
  getCurrent: (state) => state.pointOfSale || null,
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
