import Jwt from '@/services/jwt'
import ProductService from '@/modules/saleFlow/services/product.service'
import OperatorsConstants from '@/constants/sale/operators.constants'

const namespaced = true

const state = {
  availableProductType: [],
  products: [],
  productTypesOptions: {
    items: [],
  },
  productResidentialPointAdditionalTypes: {
    items: [],
  },
  productFilters: {
    productTypeId: null,
    offerInFocus: null,
    searchArgument: null,
  }
}

const mutations = {
  SET_AVAILABLE_PRODUCT_TYPE (state, productType) {
    state.availableProductType = productType
  },
  SET_PRODUCTS (state, products) {
    state.products = products
  },
  SET_PRODUCT_TYPES_OPTIONS (state, productTypesOptions) {
    state.productTypesOptions = productTypesOptions
  },
  SET_PRODUCT_RESIDENTIAL_POINT_ADDITIONAL_TYPES (state, productResidentialPointAdditionalTypes) {
    state.productResidentialPointAdditionalTypes = productResidentialPointAdditionalTypes
  },
  SET_PRODUCT_FILTERS (state, filters) {
    state.productFilters = {
      ...state.productFilters,
      ...filters
    }
  },
}

const actions = {
  async setAvailableProductType ({ commit }, productType) {
    return commit('SET_AVAILABLE_PRODUCT_TYPE', productType)
  },
  async setProducts({ commit }, products) {
    return commit('SET_PRODUCTS', products)
  },
  async setProductTypesOptions({ commit }, productTypesOptions) {
    return commit('SET_PRODUCT_TYPES_OPTIONS', productTypesOptions)
  },
  async setProductResidentialPointAdditionalTypes({ commit }, productResidentialPointAdditionalTypes) {
    return commit('SET_PRODUCT_RESIDENTIAL_POINT_ADDITIONAL_TYPES', productResidentialPointAdditionalTypes)
  },
  async setProductFilters({ commit }, filters) {
    return commit('SET_PRODUCT_FILTERS', filters)
  }
}

const getters = {
  getAvailableProductType: (state) => state.availableProductType || [],
  getAvailableProductTypeDecoded: (state, getters) => getters.getAvailableProductType.map(productTypeId => Jwt.parseJwt(productTypeId)) || [],
  hasAvailableProductTypeClaro: (state, getters) => ProductService.checkGroupHasProductTypeIdsDecoded(getters.getAvailableProductTypeDecoded, OperatorsConstants.CLARO),
  hasAvailableProductTypeTim: (state, getters) => ProductService.checkGroupHasProductTypeIdsDecoded(getters.getAvailableProductTypeDecoded, OperatorsConstants.TIM),
  hasAvailableProductTypeMcafee: (state, getters) => ProductService.checkGroupHasProductTypeIdsDecoded(getters.getAvailableProductTypeDecoded, OperatorsConstants.MCAFEE),
  getProducts: (state, getters, rootState, rootGetters) => {
    if (!Array.isArray(state?.products)) {
      return []
    }

    const partnerAvailableProductsIdsByProductType = rootGetters['getPartnerConfiguration']?.availableProducts || {}

    return state.products.filter(item => {
      const product = item?.product || {}
      const productTypeIdDecoded = Jwt.parseJwt(product?.productTypeId)?.id || null
      const productsIdsAvailable = partnerAvailableProductsIdsByProductType[productTypeIdDecoded] || []
      const productTypeIdFilter = state.productFilters?.productTypeId || null
      const offerInFocusFilter = state.productFilters?.offerInFocus || null
      const searchArgumentFilter = state.productFilters?.searchArgument || null

      if (productsIdsAvailable?.length > 0 && productsIdsAvailable?.includes(product?.id) === false) {
        return false
      }

      if (offerInFocusFilter && offerInFocusFilter !== product?.offerInFocus) {
        return false
      }

      if (productTypeIdFilter && productTypeIdFilter !== product?.productTypeId) {
        return false
      }

      if (searchArgumentFilter && product?.name?.toLowerCase().search(searchArgumentFilter?.toLowerCase()) === -1) {
        return false
      }

      return true
    })
  },
  getProductTypesOptions: (state) => state.productTypesOptions?.items || [],
  getProductResidentialPointAdditionalTypes: (state) => state.productResidentialPointAdditionalTypes?.items || []
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
