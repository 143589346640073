import Api from '@/services/api'
import BrowserStorage from "@/services/storage/browserStorage"
import Regionalization from "@/constants/regionalization/regionalization.constants"

const setCurrentAddress = function (address) {
    BrowserStorage.set(Regionalization.REGIONALIZATION, btoa(JSON.stringify(address)))
}

const getCurrentAddress = function () {
    try {
        return JSON.parse(atob(BrowserStorage.get(Regionalization.REGIONALIZATION)))
    } catch (e) {
        return null
    }
}

const findByPostalCode = async function (postalCode) {
    return Api.get(`api/address/${postalCode}`)
}

export default {
    setCurrentAddress,
    getCurrentAddress,
    findByPostalCode
}
