import BrowserStorage from "@/services/storage/browserStorage"
import CartConstants from "@/constants/cart/cart.constants"

const setCartStorage = function (cart) {
    BrowserStorage.set(CartConstants.CART_STORAGE_KEY, btoa(JSON.stringify(cart)))
}

const getCartStorage = function () {
    try {
        return JSON.parse(atob(BrowserStorage.get(CartConstants.CART_STORAGE_KEY, '')))
    } catch (e) {
        return null
    }
}

export default {
    setCartStorage,
    getCartStorage
}