import SaleFlowService from '../services/saleFlow.service'
import CheckoutStore from '@/modules/checkout/store'
import CartStore from './cart'
import ProductStore from './product'
import OperatorsStore from './operators'
import ScheduleStore from './schedule'
import ProductService from "@/modules/saleFlow/services/product.service"
import PartnerService from "@/services/partner/partner.service"

const namespaced = true

const state = {
  saleOfService: {
    id: null,
    customerId: null,
    pointOfSaleId: null,
    status: null,
    statusDescription: null,
    step: null,
    stepDescription: null,
    updatedAt: null,
  },
  orders: [],
  orderAlreadyGenerated: false,
  indication: {
    userIndicatedId: null,
    pointOfSaleId: null,
    usersToIndication: [],
    promoterName: '',
    promoterCpf: ''
  },
  acceptedTerm: {
    id: null,
  }
}

const mutations = {
  SET_SALE_OF_SERVICE (state, {
    id,
    customerId,
    pointOfSaleId,
    status,
    statusDescription,
    step,
    stepDescription,
    updatedAt
  }) {
    state.saleOfService = {
      id,
      customerId,
      pointOfSaleId,
      status,
      statusDescription,
      step,
      stepDescription,
      updatedAt,
    }
  },
  SET_SALE_OF_SERVICE_EMPTY (state) {
    state.saleOfService = {
      id: null,
      customerId: null,
      pointOfSaleId: null,
      status: null,
      statusDescription: null,
      step: null,
      stepDescription: null,
      updatedAt: null,
    }
  },
  SET_ORDERS (state, orders) {
    state.orders = orders
  },
  SET_ORDER_ALREADY_GENERATED (state, orderAlreadyGenerated) {
    state.orderAlreadyGenerated = orderAlreadyGenerated
  },
  SET_USERS_TO_INDICATION (state, users) {
    state.indication.usersToIndication = users
  },
  SET_POINT_OF_SALE_FOR_USERS_TO_INDICATION (state, pointOfSaleId) {
    state.indication.pointOfSaleId = pointOfSaleId
  },
  SET_USER_INDICATED_TO_INDICATION(state, payload) {
    state.indication.promoterName = payload.promoterName,
    state.indication.promoterCpf = payload.promoterCpf,
    state.indication.userIndicatedId = payload.idToken
  },
  SET_USER_PROMOTOR_TO_INDICATION (state, payload) {
    state.indication.promoterName = payload.promoterName,
    state.indication.promoterCpf = payload.promoterCpf,
    state.indication.userIndicatedId = payload.userIndicatedId
  },
  SET_ACCEPTED_TERM (state, id) {
    state.acceptedTerm.id = id
  }
}

const actions = {
  async copySaleOfServiceIdToTransferArea ({ getters }) {
    return navigator.clipboard.writeText(getters?.getSaleOfService?.id)
  },
  async addCustomer ({ commit, getters, rootGetters }, customer) {
    if (customer?.id !== getters.getSaleOfService?.customerId) {
      await commit('SET_SALE_OF_SERVICE_EMPTY')
    }
    return SaleFlowService.addCustomer(
      getters.getSaleOfService?.id,
      customer?.id,
      rootGetters['auth/user/pointOfSale/getCurrent']?.id,
      getters.getAcceptedTerm?.id?.id || null,
        PartnerService.getActivePartner()?.toLowerCase() || null
    )
      .then (response => {
        commit('SET_SALE_OF_SERVICE', response?.saleOfService)
      })
  },
  async getProductsCatalog ({ commit, getters, dispatch }) {
    return SaleFlowService.productsCatalog(
      getters.getSaleOfService?.id,
      getters['product/getAvailableProductType']
    )
      .then(response => {
        commit('SET_SALE_OF_SERVICE', response?.saleOfService)
        dispatch('product/setProducts', response?.products)
        dispatch('product/setProductTypesOptions', response?.productTypesOptions)
        dispatch('product/setProductResidentialPointAdditionalTypes', response?.productResidentialPointAdditionalTypes)
        dispatch('schedule/setProductInstallationSchedulePeriod', response?.productInstallationSchedulePeriod)
      })
  },
  async getAllProductsCatalog ({ getters, rootGetters, dispatch }) {
    return SaleFlowService.productsCatalog(
        null,
        getters['product/getAvailableProductType'],
        rootGetters['address/getCurrentAddress']?.id || null
    )
        .then(response => {
          dispatch('product/setProducts', response?.products)
          dispatch('product/setProductTypesOptions', response?.productTypesOptions)
          dispatch('product/setProductResidentialPointAdditionalTypes', response?.productResidentialPointAdditionalTypes)
          dispatch('schedule/setProductInstallationSchedulePeriod', response?.productInstallationSchedulePeriod)
        })
  },
  async validateIfAllCartProductsCanBeSold ({ getters, rootGetters }) {
    const { cartItemsNotFound } = ProductService.matchAllCartProductsWithSaleFlowProductsCatalog(rootGetters['cart/getCartItems'], getters['product/getProducts'])
    return {
      cartItemsNotFound,
      validated: cartItemsNotFound?.length === 0,
    }
  },
  async validateIfProductCanBeSold ({ getters }, product) {
    const { cartItemsNotFound } = ProductService.matchAllCartProductsWithSaleFlowProductsCatalog([ product ], getters['product/getProducts'])

    return {
      validated: cartItemsNotFound?.length === 0,
    }
  },
  async processCartToCheckout ({ getters, rootGetters, dispatch }) {
    const { productsToAdd, productsToRemove } = ProductService.getProductsToAddAndToRemoveByCartAndCheckoutProducts(rootGetters['cart/getCartItems'], getters['cart/getCheckoutProductsItems'])
    // Removing from cart
    productsToRemove.forEach(checkoutProductItem => {
      dispatch('removeProduct', checkoutProductItem?.id)
    })
    // Adding to cart
    productsToAdd.forEach(cartItem => {
      dispatch('addProduct', {
        id: null,
        product: cartItem?.product,
        promotion: cartItem?.promotion,
        productResidentialPointAdditionalAmount: cartItem?.productResidentialPointAdditionalAmount || null,
        productResidentialPointAdditionalTypeId: cartItem?.productResidentialPointAdditionalType?.id || null
      })
    })
  },
  async addProduct ({ getters, dispatch }, {
    id,
    product,
    promotion,
    productResidentialPointAdditionalType,
    productResidentialPointAdditionalAmount
  }) {
    const additionalAmount = productResidentialPointAdditionalAmount || 0
    return SaleFlowService.addProduct(
      id,
      getters?.getSaleOfService?.id,
      product?.id,
      promotion?.id,
      additionalAmount > 0 ? productResidentialPointAdditionalType?.id : null,
      additionalAmount
    )
      .then(response => {
        dispatch('cart/addProduct', {
          checkoutProductItem: response?.checkoutProductItem,
          product,
          promotion,
          productResidentialPointAdditionalType,
          productResidentialPointAdditionalAmount
        })
      })
  },
  async removeProduct ({ dispatch }, checkoutProductId) {
    return SaleFlowService.removeProduct(checkoutProductId)
      .then(response => {
        if (response?.deleted !== true) {
          return
        }
        dispatch('cart/removeProduct', checkoutProductId)
      })
  },
  async installationSchedule ({ getters, dispatch }, { schedules, contacts }) {
    return SaleFlowService.installationSchedule(
      getters['schedule/getScheduleInstallation']?.id || null,
      getters.getSaleOfService?.id,
      schedules[0]?.date || null,
      schedules[0]?.productInstallationSchedulePeriodId || null,
      schedules[1]?.date || null,
      schedules[1]?.productInstallationSchedulePeriodId || null,
      contacts,
    )
      .then(response => {
        dispatch('schedule/setInstallationSchedule', {
          id: response?.id,
          installationScheduleDateOne: response?.installationScheduleDateOne?.date,
          installationSchedulePeriodOneId: response?.installationSchedulePeriodOneId,
          installationScheduleDateTwo: response?.installationScheduleDateTwo?.date,
          installationSchedulePeriodTwoId: response?.installationSchedulePeriodTwoId
        })
        dispatch('schedule/setContacts', response?.contacts || [])
      })
  },
  async checkoutPaymentOptions ({ getters, dispatch }) {
    return SaleFlowService.checkoutPaymentOptions(getters.getSaleOfService?.id)
      .then((response) => {
        dispatch('checkout/setCheckoutPaymentOptions', response?.paymentOptions)
      })
  },
  async productBanks ({ getters, dispatch }) {
    const banks = getters['checkout/getProductBanks'] || []
    if (Array.isArray(banks) && banks.length > 0) {
      return Promise.resolve()
    }
    return SaleFlowService.productBanks()
      .then(response => {
        dispatch('checkout/setProductBanks', response?.banks)
      })
  },
  async checkoutOrder ({ getters, commit }) {
    const products = []
    getters['checkout/getCheckoutPaymentItems']?.forEach(checkoutPaymentItem => {
      if (checkoutPaymentItem?.checkoutPaymentData) {
        products.push({
          checkoutProductItemId: checkoutPaymentItem?.checkoutProductItem?.id,
          payment: checkoutPaymentItem?.checkoutPaymentData,
        })
      }
    })
    return SaleFlowService.checkoutOrder(
      getters.getSaleOfService?.id,
      products
    ).then (response => {
      commit('SET_ORDER_ALREADY_GENERATED', response?.orderGeneratedSuccessfully || false)
      if (response.orderGeneratedSuccessfully !== true) {
        return Promise.reject(response)
      }
      return Promise.resolve(response)
    })
  },
  async checkoutActivateService ({ getters, commit, dispatch }) {
    return SaleFlowService.checkoutActivateService(getters.getSaleOfService?.id)
      .then(response => {
        commit('SET_SALE_OF_SERVICE', response?.details?.saleOfService)
        commit('SET_ORDERS', response?.details?.orders)

        return Promise.resolve(response)
      })
      .catch(response => {
        if (response?.status === 422) {
          // Set intent to open modal
          dispatch('operators/claro/authenticate/setIntentToOpenModalByApiMessage', response?.data?.message)
        }
        return Promise.reject(response)
      })
  },
  async checkoutPortabilitySendCodeAuthorization (context, phone) {
    return SaleFlowService.checkoutPortabilitySendCodeAuthorization(phone)
      .then(response => {
        if (response?.sent !== true) {
          return Promise.reject(response)
        }
        return Promise.resolve(response)
      })
  },
  async refreshOrders ({ getters, commit }) {
    return SaleFlowService.orderDetail(getters.getSaleOfService?.id)
      .then(response => {
        commit('SET_ORDERS', response?.detail?.orders)
      })
  },
  async getUsersToIndication ({ rootGetters, getters, commit }) {
    if (getters.getPointOfSaleIdForUsersToIndication === rootGetters['auth/user/pointOfSale/getCurrentLegacy']?.id) {
      return Promise.resolve()
    }
    return SaleFlowService.getUsersByPointOfSalesLegacyId(rootGetters['auth/user/pointOfSale/getCurrentLegacy']?.id)
      .then(response => {
        commit('SET_USERS_TO_INDICATION', response?.users?.items)
        commit('SET_POINT_OF_SALE_FOR_USERS_TO_INDICATION', rootGetters['auth/user/pointOfSale/getCurrentLegacy']?.id)
      })
  },
  async addIndicationSeller ({ getters }) {
    if (!getters.getUserIndicatedId) {
      return Promise.resolve()
    }
    return SaleFlowService.addIndicationSeller(getters.getSaleOfService?.id, getters.getUserIndicatedId)
      .then(response => response?.added === false ? Promise.reject(response) : Promise.resolve(response))
  },
  async addIndicationPromoter({ getters }) {
    if (!getters.getPromoterIndicated?.promoterName || !getters.getPromoterIndicated?.promoterCpf) {
      return Promise.resolve()
    }
    return SaleFlowService.addIndicationPromoter(getters.getSaleOfService?.id, getters.getPromoterIndicated)
      .then(response => response?.added === false ? Promise.reject(response) : Promise.resolve(response))
  },
  async getTriangulationCurrent({ getters, dispatch }, { checkoutProductItemId, productId, productPromotionId }) {
    return SaleFlowService.triangulationCurrent(getters.getSaleOfService?.id, productId, productPromotionId)
      .then(response => {
        dispatch('triangulation/setTriangulation', {
          key: checkoutProductItemId,
          triangulation: response?.triangulation || null
        })
      })
  },
  async addAttachment ({ getters, dispatch }, { file, saleOfServiceId }) {
    return SaleFlowService.addAttachment(
      saleOfServiceId ? saleOfServiceId : getters.getSaleOfService?.id,
      file
    )
      .then(response => {
        dispatch('attachment/add', {
          id: response?.attachment?.id?.id,
          link: response?.attachment?.link,
          file,
        })
      })
  },
  async removeAttachment ({ dispatch }, id) {
    return SaleFlowService.removeAttachment(id)
      .then(response => {
        if (response?.deleted !== true) {
          return Promise.reject(response)
        }
        dispatch('attachment/remove', id)
      })
  },
  async setIndicationUser({ commit }, payload) {
    return commit('SET_USER_INDICATED_TO_INDICATION', payload)
  },
  async setPromoterUser({ commit }, payload) {
    return commit('SET_USER_PROMOTOR_TO_INDICATION', payload)
  },
  async setAcceptedTerm ({ commit }, id) {
    return commit('SET_ACCEPTED_TERM', id)
  }
}

const getters = {
  getSaleOfService: (state) => state.saleOfService,
  getSellerId: (state) => state.sellerId,
  getOrders: (state) => state.orders || [],
  isOrderAlreadyGenerated: (state) => state.orderAlreadyGenerated || false,
  getUsersToIndication: (state) => state.indication.usersToIndication || [],
  getUserIndicatedId: (state) => state.indication.userIndicatedId || null,
  getPromoterIndicated: (state) => state.indication || null,
  getPointOfSaleIdForUsersToIndication: (state) => state.indication.pointOfSaleId || null,
  getAcceptedTerm: (state) => state.acceptedTerm || null,
}

const saleFlowStore = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules: {
    cart: CartStore, 
    checkout: CheckoutStore,
    product: ProductStore,
    operators: OperatorsStore,
    schedule: ScheduleStore,
  }
}
export function registerModule (store) {
    store.registerModule('saleFlow', saleFlowStore)
}
export function unregisterModule (store) {
    store.unregisterModule('saleFlow', saleFlowStore)
}
export default saleFlowStore;
