import { darkWebEmailScan } from "@/modules/mcafee/service/mcafee.service.js"

const mcafeeModule = {
    namespaced: true,

    state: () => ({
      email: null,
      breachCount: null
    }),
    mutations: {
      SET_EMAIL(state, email) {
        state.email = email;
      },
      SET_BREACH_COUNT(state, count) {
        state.breachCount = count;
      }
    },
    actions: {
      async verifyEmailBreach({ commit }, email) {
          return new Promise((resolve, reject) => {
              darkWebEmailScan(email)
                  .then(response => {
                      if (!response || response.error) {
                          reject("Unexpected response");
                      }
                      commit('SET_EMAIL', email);
                      commit('SET_BREACH_COUNT', response.breachCount);
                      resolve(response.breachCount)
                  })
                  .catch(err => {
                      console.error('Erro na requisição ID Scan:', err);
                      reject(err);
                  })
          })
      }
    },
    getters: {
      getEmail: (state) => state.email,
      getBreachCount: (state) => state.breachCount
    }
  }
export function registerModule(store) {
  if (!store.hasModule('mcafee')) {
    store.registerModule('mcafee', mcafeeModule)
  }
}
export function unregisterModule(store) {
  store.unregisterModule('mcafee')
}

export default mcafeeModule;
