import ClaroStore from './claro'

const namespaced = true

const modules = {
  claro: ClaroStore
}

export default {
  namespaced,
  modules,
}
