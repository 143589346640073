import OperatorsConstants from '@/constants/sale/operators.constants'
import ProductTypesOptionsConstants from '@/constants/sale/productTypesOptions.constants'

export default {
  [OperatorsConstants.CLARO]: [
    ProductTypesOptionsConstants.CLARO_RESIDENTIAL_TV,
    ProductTypesOptionsConstants.CLARO_RESIDENTIAL_BANDA_LARGA,
    ProductTypesOptionsConstants.CLARO_RESIDENTIAL_FIXO,
    ProductTypesOptionsConstants.CLARO_CONTROLE_FACIL,
    ProductTypesOptionsConstants.CLARO_PRE_PAGO,
  ],
  [OperatorsConstants.TIM]: [
    ProductTypesOptionsConstants.TIM_PRE_PAGO,
    ProductTypesOptionsConstants.TIM_CONTROLE_EXPRESS,
  ],
  [OperatorsConstants.MCAFEE]: [
    ProductTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION,
  ],
}
