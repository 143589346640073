import BrowserStorage from "@/services/storage/browserStorage"
import CookieConstants from "@/constants/policy/cookie.constants"
import Api from '@/services/api';

const setAcceptedCookie = function () {
    BrowserStorage.set(CookieConstants.ACCEPTED_COOKIES, 1)
}

const getAcceptedCookie = function () {
   return parseInt(BrowserStorage.get(CookieConstants.ACCEPTED_COOKIES))
}

export async function sendCookieAcceptance(
    partner,
    latitude,
    longitude,
    locationName,
    ip
  ) 
  {
    const userAgent = navigator.userAgent;
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    const requestData = {
      origin: `TradeAppOne Online landing_page_- ${partner || "unknownpartner"}`,
      browser: userAgent,
      userIp: ip,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      address: locationName,
      accepted: true,
      acceptanceDate: formattedDate,
    };  
    try {
      const response = await Api.post(
        "/api/sale-of-services/acceptance-cookies-term",
        requestData
      );
      return response.data;
    } catch (error) {
      throw new Error("Erro ao enviar os dados para a API");
    }
  }
  
  
export default {
    setAcceptedCookie,
    getAcceptedCookie,
    sendCookieAcceptance

}