import Api from '@/services/api'
import Swal from "sweetalert2";

const addCustomer = async function (saleOfServiceId, customerId, pointOfSaleId, acceptedTermId, partner) {
    return Api.post('api/sale-of-services/add-customer', {
        saleOfServiceId,
        customer: { customerId },
        pdvId: pointOfSaleId,
        termId: acceptedTermId,
        tagOrigin: `Fluxo de venda Online - ${partner || 'unknownpartner'}`,
    })
}

const productsCatalog = async function (
  saleOfServiceId,
  productType,
  addressId
) {
    return Api.post(
        saleOfServiceId ? `api/sale-of-services/products-catalog/${saleOfServiceId}` : 'api/sale-of-services/products-catalog',
        {
            filters: {
                productType,
                addressId: addressId
            },
        }
    );
};

const addProduct = async function (
    id,
    saleOfServiceId,
    productId,
    productPromotionId,
    productResidentialPointAdditionalTypeId,
    productResidentialPointAdditionalAmount
) {
    return Api.post('api/sale-of-services/checkout/item/add', {
        id,
        saleOfServiceId,
        productId,
        productPromotionId,
        productResidentialPointAdditionalTypeId,
        productResidentialPointAdditionalAmount
    })
}

const removeProduct = async function (id) {
    return Api.post('api/sale-of-services/checkout/item/delete', { id })
}

const installationSchedule = async function (
    id,
    saleOfServiceId,
    installationScheduleDateOne,
    installationSchedulePeriodIdOne,
    installationScheduleDateTwo,
    installationSchedulePeriodIdTwo,
    contacts
) {
    return Api.post('api/sale-of-services/installation-schedule', {
        id,
        saleOfServiceId,
        installationScheduleDateOne,
        installationSchedulePeriodIdOne,
        installationScheduleDateTwo,
        installationSchedulePeriodIdTwo,
        contacts
    })
}

const checkoutPaymentOptions = async function (saleOfServiceId) {
    return Api.post('api/sale-of-services/checkout/payment-options', { saleOfServiceId })
}

const productBanks = async function () {
    return Api.get('api/sale-of-services/product-banks/claro-residential')
}

const checkoutOrder = async function (saleOfServiceId, products) {
    return Api.post('api/sale-of-services/checkout/order', { saleOfServiceId, products })
}

const checkoutActivateService = async function (saleOfServiceId) {
    return Api.post('api/sale-of-services/checkout/activate-service', { saleOfServiceId })
}

const checkoutPortabilitySendCodeAuthorization = async function (phone) {
    return Api.post('api/sale-of-services/checkout/portability/send-code-authorization', { phone })
}
const orderDetail = async function (saleOfServiceId) {
        const apiUrl = Api.get(`api/sale-of-services/order/${saleOfServiceId}`);
        try {
            const response = await Api.get(apiUrl,{saleOfServiceId});
        } catch (error) {
          Swal.fire({
            title: 'Atenção: Você precisa completar o seu pedido. tente novamente',
            type: 'warning',
            confirmButtonText: 'Ok',   
          });
            if(!saleOfServiceId) Swal.fire('Não há pedidos no momento') 
        }
    }  
const triangulationCurrent = async function (saleOfServiceId, productId, promotionId) {
    return Api.post('api/sale-of-services/triangulation/current', {
        triangulation: {
            saleOfServiceId,
            productId,
            promotionId
        }
    })
}
const getUsersByPointOfSalesLegacyId = async function (pointOfSalesLegacyId) {
    return Api.post('api/sale-of-services/users/getUsersByPointOfSalesLegacyId', { pointOfSalesLegacyId })
}

const addIndicationSeller = async function (saleOfServiceId, payload) {
    return Api.post('api/sale-of-services/add-indication-seller', {
        saleOfServiceId,
        seller: {
            id: payload,
        }
    })
}

const addIndicationPromoter = async function (saleOfServiceId, payload) {
    return Api.post('/api/sale-of-services/add-indication-promoter', {
        saleOfServiceId,
        promoter: {
            name: payload.promoterName,
            cpf: payload.promoterCpf
        }
    })
}

const addAttachment = async function (saleOfServiceId, file) {
    const form = new FormData()

    form.append('saleOfServiceId', saleOfServiceId)
    form.append('attachment', file)

    return Api.post('api/sale-of-services/add-attachment', form)
}

const removeAttachment = async function (attachmentId) {
    return Api.post('api/sale-of-services/remove-attachment', { attachmentId })
}

const getCustomerTerm = async function () {
    return Api.post('api/sale-of-services/documents/term/customer')
}

export default {
    addCustomer,
    productsCatalog,
    addProduct,
    removeProduct,
    installationSchedule,
    checkoutPaymentOptions,
    productBanks,
    checkoutOrder,
    checkoutActivateService,
    checkoutPortabilitySendCodeAuthorization,
    orderDetail,
    getUsersByPointOfSalesLegacyId,
    addIndicationSeller,
    addIndicationPromoter,
    triangulationCurrent,
    addAttachment,
    removeAttachment,
    getCustomerTerm
}
