import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueTheMask from 'vue-the-mask'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import Icon from '@/components/Icon/Icon'
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment'






import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vuepic/vue-datepicker/dist/main.css'


import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPhone);

const app = createApp(App)
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(router)
app.use(BootstrapVue3)
app.use(VueTheMask)
app.use(store)
app.use(VueSweetalert2);
app.mount('#app')

app.component('Icon', Icon)
app.component('VueDatePicker', VueDatePicker);

moment.locale('pt-BR')

