import AuthService from "@/modules/auth/services/auth.service";
import Jwt from "@/services/jwt"
import PointOfSaleStore from "@/modules/auth/store/pointOfSale";

const namespaced = true

const state = {}

const mutations = {}

const actions = {}

const getters = {
    getSeller: () => Jwt.parseJwt(Jwt.parseJwt(AuthService.getSellerToken())?.uid) || null
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
    modules: {
        pointOfSale: PointOfSaleStore
    }
}
