import Api from '@/services/api'

const findByCpf = async function (cpf) {
    return Api.get(`api/customer/${cpf}`)
}

const saveCustomer = async function (customer) {
    return Api.post('api/customer', { customer: customer, tagOrigin: 'Fluxo de Vendas Online do TradeAppOne' })
}

const associateAddress = async function (customer, address) {
    return Api.post('api/customer/associate-address', { customer, address })
}

export default {
    findByCpf,
    saveCustomer,
    associateAddress
}
