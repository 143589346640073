const namespaced = true

const state = {
    checkoutProductsItems: [],
    checkoutProductsDetails: {},
}

const mutations = {
    ADD_CHECKOUT_PRODUCT_ITEM (state, {
        id,
        productId,
        productPromotionId,
        productResidentialPointAdditionalAmount,
        productResidentialPointAdditionalTypeId,
        saleOfServiceId
    }) {
        state.checkoutProductsItems.push({
            id,
            productId,
            productPromotionId,
            productResidentialPointAdditionalAmount,
            productResidentialPointAdditionalTypeId,
            saleOfServiceId
        })
    },
    ADD_CHECKOUT_PRODUCT_DETAIL (state, {
        key,
        product,
        promotion,
        productResidentialPointAdditionalType
    }) {
        state.checkoutProductsDetails[key] = {
            product,
            promotion,
            productResidentialPointAdditionalType
        }
    },
    REMOVE_CHECKOUT_PRODUCT_ITEM (state, checkoutProductId) {
        state.checkoutProductsItems = state.checkoutProductsItems
            .filter(checkoutProductItem => checkoutProductItem?.id !== checkoutProductId)
    },
    REMOVE_CHECKOUT_PRODUCT_DETAIL (state, key) {
        const checkoutProductsDetails = state.checkoutProductsDetails

        delete checkoutProductsDetails[key]
    },
}

const actions = {
    async addProduct ({ commit }, {
        checkoutProductItem,
        product,
        promotion,
        productResidentialPointAdditionalType
    }) {
        commit('ADD_CHECKOUT_PRODUCT_ITEM', checkoutProductItem)
        commit('ADD_CHECKOUT_PRODUCT_DETAIL', {
            key: checkoutProductItem?.id,
            product,
            promotion,
            productResidentialPointAdditionalType
        })
    },
    async removeProduct ({ commit }, checkoutProductId) {
        commit('REMOVE_CHECKOUT_PRODUCT_ITEM', checkoutProductId)
        commit('REMOVE_CHECKOUT_PRODUCT_DETAIL', checkoutProductId)
    }
}

const getters = {
    getCheckoutProductsItems: (state) => state.checkoutProductsItems,
    getCheckoutProductsQuantity: (state) => state.checkoutProductsItems.length || 0,
    getCheckoutProductsDetails: (state) => state.checkoutProductsDetails,
    getCheckoutDetails: (state, getters) => {
        const items = []
        let total = 0

        for (const index in getters.getCheckoutProductsItems) {
            const item = getters.getCheckoutProductsItems[index]
            const itemData = getters.getCheckoutProductsDetails[item?.id] || {}

            const promotionPrice = itemData?.promotion?.price?.price || 0
            const pointAdditionalPrice = itemData?.productResidentialPointAdditionalType?.price || 0
            const pointAdditionalAmount = item?.productResidentialPointAdditionalAmount || 0
            const pointsAdditionalPrice = pointAdditionalPrice * pointAdditionalAmount
            const itemPrice = promotionPrice + pointsAdditionalPrice

            items.push({
                item: {
                    checkoutProduct: item,
                    ...itemData,
                },
                pointsAdditionalPrice: pointsAdditionalPrice,
                price: itemPrice
            })

            total += itemPrice
        }

        return {items, total}
    },
    hasCheckoutProductThatRequireClaroAuthenticate: (state) => {
        return Object.values(state.checkoutProductsDetails).some((item) => item?.product?.requireClaroAuthenticate || false)
    },
    hasCheckoutProductThatRequireInstallationSchedule: (state, getters) => {
        return state.checkoutProductsItems.some(item => getters.getCheckoutProductsDetails[item.id]?.product?.requireInstallationSchedule || false)
    },
}


export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}