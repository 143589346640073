import JwtService from '@/services/jwt'

const namespaced = true

const state = {
  defaultPaymentDataByType: {},
  checkoutPaymentDataItems: {},
  checkoutPaymentOptions: [],
  productBanks: {
    items: []
  },
}

const mutations = {
  SET_DEFAULT_PAYMENT_DATA (state, { type, paymentData }) {
    state.defaultPaymentDataByType[type] = paymentData
  },
  SET_CHECKOUT_PAYMENT_DATA_ITEM (state, { key, checkoutPaymentData }) {
    state.checkoutPaymentDataItems[key] = checkoutPaymentData
  },
  SET_CHECKOUT_PAYMENT_OPTIONS (state, checkoutPaymentOptions) {
    state.checkoutPaymentOptions = checkoutPaymentOptions
  },
  SET_PRODUCT_BANKS (state, productBanks) {
    state.productBanks = productBanks
  },
}

const actions = {
  setDefaultPaymentData({ commit }, { type, paymentData }) {
    commit('SET_DEFAULT_PAYMENT_DATA', {
      type,
      paymentData
    })
  },
  setCheckoutPaymentDataItem ({ commit }, { checkoutPaymentItem, checkoutPaymentData }) {
    commit('SET_CHECKOUT_PAYMENT_DATA_ITEM', {
      key: checkoutPaymentItem?.checkoutProductItem?.id,
      checkoutPaymentData: checkoutPaymentData
    })
  },
  setCheckoutPaymentOptions ({ commit }, checkoutPaymentOptions) {
    commit('SET_CHECKOUT_PAYMENT_OPTIONS', checkoutPaymentOptions)
  },
  setProductBanks ({ commit }, productBanks) {
    commit('SET_PRODUCT_BANKS', productBanks)
  },
}

const getters = {
  getCheckoutPaymentOptions: (state) => state.checkoutPaymentOptions || [],
  getProductBanks: (state) => state.productBanks?.items || [],
  getCheckoutPaymentDataItems: (state) => state.checkoutPaymentDataItems || {},
  getDefaultPaymentDataByType: (state) => state.defaultPaymentDataByType || {},
  getCheckoutPaymentItems: (state, getters, rootState, rootGetters) => {
    const checkoutProductsItems = rootGetters['saleFlow/cart/getCheckoutProductsItems']
    const checkoutProductsDetails = rootGetters['saleFlow/cart/getCheckoutProductsDetails']
    const checkoutPaymentDataItems = getters.getCheckoutPaymentDataItems
    const checkoutPaymentItems = []

    getters.getCheckoutPaymentOptions.forEach(checkoutPayment => {
      const checkoutProductItem = checkoutProductsItems
        .find((item) => item?.productId === checkoutPayment?.productId && item?.productPromotionId === checkoutPayment?.promotionId)

      const checkoutProductDetail = checkoutProductsDetails[checkoutProductItem?.id] || null
      const checkoutPaymentData = checkoutPaymentDataItems[checkoutProductItem?.id] || null

      // Double check to mount payment list
      if (!checkoutProductItem || !checkoutProductDetail) {
        return
      }

      checkoutPayment?.paymentOptions?.items.forEach(paymentOption => {
        paymentOption.decodedId = JwtService.parseJwt(paymentOption?.id)?.id || null
      })

      checkoutPaymentItems.push({
        checkoutPayment,
        checkoutProductItem,
        checkoutProductDetail,
        checkoutPaymentData,
      })
    })

    return checkoutPaymentItems
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}