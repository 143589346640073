const namespaced = true

const state = {
  productInstallationSchedulePeriod: {
    items: []
  },
  installationSchedule: {
    id: null,
    dateOne: null,
    periodOneId: null,
    dateTwo: null,
    periodTwoId: null,
  },
  contacts: [],
}

const mutations = {
  SET_PRODUCT_INSTALLATION_SCHEDULE_PERIOD (state, productInstallationSchedulePeriod) {
    state.productInstallationSchedulePeriod = productInstallationSchedulePeriod
  },
  SET_INSTALLATION_SCHEDULE (state, {
    id,
    installationScheduleDateOne,
    installationSchedulePeriodOneId,
    installationScheduleDateTwo,
    installationSchedulePeriodTwoId
  }) {
    state.installationSchedule = {
      id: id,
      dateOne: installationScheduleDateOne,
      periodOneId: installationSchedulePeriodOneId,
      dateTwo: installationScheduleDateTwo,
      periodTwoId: installationSchedulePeriodTwoId,
    }
  },
  SET_CONTACTS (state, contacts) {
    state.contacts = contacts
  },
}

const actions = {
  setProductInstallationSchedulePeriod ({ commit }, productInstallationSchedulePeriod) {
    commit('SET_PRODUCT_INSTALLATION_SCHEDULE_PERIOD', productInstallationSchedulePeriod)
  },
  setInstallationSchedule({ commit }, {
    id,
    installationScheduleDateOne,
    installationSchedulePeriodOneId,
    installationScheduleDateTwo,
    installationSchedulePeriodTwoId
  }) {
    commit('SET_INSTALLATION_SCHEDULE', {
      id,
      installationScheduleDateOne,
      installationSchedulePeriodOneId,
      installationScheduleDateTwo,
      installationSchedulePeriodTwoId
    })
  },
  setContacts ({ commit }, contacts) {
    commit('SET_CONTACTS', contacts)
  }
}

const getters = {
  getProductInstallationSchedulePeriod: (state) => state?.productInstallationSchedulePeriod?.items || [],
  getScheduleInstallation: (state) => state?.installationSchedule,
  getContacts: (state) => state?.contacts || [],
  getSchedules: (state, getters) => {
    const schedules = [
      {
        date: getters.getScheduleInstallation.dateOne,
        productInstallationSchedulePeriodId: getters.getScheduleInstallation.periodOneId
      },
      {
        date: getters.getScheduleInstallation.dateTwo,
        productInstallationSchedulePeriodId: getters.getScheduleInstallation.periodTwoId
      }
    ]

    return schedules.filter((schedule) => schedule.date !== null && schedule.productInstallationSchedulePeriodId !== null)
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
