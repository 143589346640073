const googleAnalyticScript = function (w,d,s,l,i) {
    w[l]=w[l]||[];
    w[l].push({ 'gtm.start': new Date().getTime(), event:'gtm.js' });
    let f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;
    j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
}

const gtag = function () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
}

const startTracking = function (tagId) {
    googleAnalyticScript(window,document,'script','dataLayer', tagId)
    gtag('js', new Date());
    gtag('config', tagId);
}

const gtagPush = function (object) {
    window.dataLayer.push(object)
}

export default {
    startTracking,
    gtagPush
}
