import Api from '@/services/api'
import Configuration from '@/services/configuration'
import BrowserStorage from '@/services/storage/browserStorage'
import TokenConstants from '../constants/token.constants'



const clearSystemToken = function () {
    BrowserStorage.remove(TokenConstants.API_TOKEN)
}

const clearSellerToken = function () {
    BrowserStorage.remove(TokenConstants.API_SELLER_TOKEN)
}

const clearCustomerToken = function () {
    BrowserStorage.remove(TokenConstants.API_CUSTOMER_TOKEN)
}

const setSystemToken = function (token) {
    BrowserStorage.set(TokenConstants.API_TOKEN, token)
}

const setSellerToken = function (token) {
    BrowserStorage.set(TokenConstants.API_SELLER_TOKEN, token)
}

const setCustomerToken = function (token) {
    BrowserStorage.set(TokenConstants.API_CUSTOMER_TOKEN, token)
}

const getSystemToken = function () {
    return BrowserStorage.get(TokenConstants.API_TOKEN)
}

const getSellerToken = function () {
    return BrowserStorage.get(TokenConstants.API_SELLER_TOKEN)
}

const getCustomerToken = function () {
    return BrowserStorage.get(TokenConstants.API_CUSTOMER_TOKEN)
}

// Integration authentication of new TradeAppOne to consume endpoints
const systemAuthentication = function () {
   clearSystemToken()
   clearSellerToken()
    

    return Api.post('api/auth', { apiKey: Configuration.api.key, secret: Configuration.api.secret })
        .then((response) => {
            setSystemToken(response?.auth?.token)

            return Promise.resolve(response)
        })
}

// Seller authentication to create sale of services
const sellerSignin = function (login, password) {
    return Api.post('api/sale-of-services/auth', { login, password })
        .then((response) => {
            setSellerToken(response?.saleOfServicesAuth?.token)

            return Promise.resolve(response)
        })
}
/// Customer authentication
const customerSignin = function (email, password) {
  return Api.post('api/customer/auth', { customer: { email, password } })
    .then((response) => {
        setCustomerToken(response?.customerAuth?.token);
        return Promise.resolve(response);
      } );
}
// Signin
const signin = function (login, password) {
    return this.systemAuthentication()
        .then(() => {
            return this.sellerSignin(login, password)
        })
}
// Logout
const logout = function () {}


export default {
    systemAuthentication,
    sellerSignin,
    customerSignin,
    signin,
    logout,
    getSystemToken,
    getSellerToken,
    getCustomerToken,
    
}
