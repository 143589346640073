import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'TradeAppOne Online - ' + (to.meta?.title ?? 'Multi serviços');
  // User already authenticated
  if (store.getters['auth/isAuthenticatedUser']) {
    next()

    return
  }

  store.dispatch('auth/authenticate').finally(() => next())
})

export default router
