import ClaroService from '@/modules/saleFlow/services/claro.service'

const namespaced = true

const state = {
  msisdnList: [],
}

const mutations = {
  SET_MSISDN_LIST (state, msisdnList) {
    state.msisdnList = msisdnList
  }
}

const actions = {
  fetchMsisdnList ({ commit, rootGetters }) {
    return ClaroService.listMsisdnForPrePago(rootGetters['saleFlow/getSaleOfService']?.id)
      .then((response) => {
        commit('SET_MSISDN_LIST', response?.numbers)
      })
  }
}

const getters = {
  getMsisdnList: (state) => state.msisdnList || []
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
