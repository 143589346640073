import AuthService from "@/modules/auth/services/auth.service";
import { HTTP_UNAUTHORIZED } from "./types/http";
import Api from "./index";
import store from "@/store";

export default function setResponseInterceptors(axiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response?.data?.error === true) {
        return Promise.reject(response?.data?.response);
      }

      return Promise.resolve(response?.data?.response);
    },
    function (error) {
      // Redirect to signin screen when token expired
      if (HTTP_UNAUTHORIZED === error?.response?.status) {
        return store.dispatch("auth/authenticate").then(() => {
          const originalRequest = error.config;
          originalRequest.headers[
            "Authorization"
          ] = AuthService.getSystemToken();
          originalRequest.headers[
            "AuthorizationSaleOfServices"
          ] = AuthService.getSellerToken();

          return Api.request(originalRequest);
        });
      }

      return Promise.reject(error);
    }
  );
}
