import store from '@/store';
import { routes as LandingPagesRoutes } from '@/modules/landingPages/router';
import { routes as SaleFlowRoutes } from '@/modules/saleFlow/router';
import PartnerService from '@/services/partner/partner.service.js';

export default [
  {
    path: '/:partner?',
    name: 'PartnerName',
    component: () => import(/* webpackChunkName: "container" */ '@/components/Container/Container'),
    beforeEnter: async (to, from, next) => {
      try {
        const partner = to.params.partner;
        const config = partner ? PartnerService.getPartnerConfiguration(partner) : null;

        if (config) {
          store.dispatch('setPartner', partner);
          store.dispatch('startPartnerTracking');
          config.theme();
        }

        next();
      } catch (error) {
        console.error('Critical Route Error :>> ', error);
        next({ name: 'NotFound' }); // @todo "Ops, ..."
      }
    },

    children: [
      ...LandingPagesRoutes,
      ...SaleFlowRoutes,
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "landingPageNotFound" */ '@/modules/landingPages/404/NotFound')
  },
];
