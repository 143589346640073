<template>
  <router-view/>
</template>

<script>
import { mapActions } from "vuex"
import Configuration from "@/services/configuration";
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";

export default {
  name: 'App',
  beforeMount() {
    this.restoreProductsCartAction()
    this.setDefaultAvailableProduct()
  },
  methods: {
    ...mapActions({
      restoreProductsCartAction: 'cart/restoreProductsCart',
      setAvailableProductTypeAction: 'saleFlow/product/setAvailableProductType',
    }),
    setDefaultAvailableProduct () {
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION],
        Configuration.productTypeOption[productTypesOptionsConstants.CLARO_RESIDENTIAL_TV],
        Configuration.productTypeOption[productTypesOptionsConstants.CLARO_RESIDENTIAL_BANDA_LARGA],
      ])
    }
  },
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
