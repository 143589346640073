import Api from '@/services/api'

const generateAuthenticateLink = function (saleOfServiceId) {
    return Api.post(`api/sale-of-services/claro-authenticate/create/${saleOfServiceId}`)
}

const listMsisdnForPrePago = function (saleOfServiceId) {
    return Api.post('api/sale-of-services/checkout/claro/list-msisdn-for-pre-pago', { saleOfServiceId })
}
const listResidentialInvoiceDueDate = function () {
    return Api.get('api/sale-of-services/checkout/claro/residential/list-invoice-due-date')
}


export default {
    generateAuthenticateLink,
    listMsisdnForPrePago,
    listResidentialInvoiceDueDate
}
