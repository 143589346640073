import AuthService from "@/modules/auth/services/auth.service";

export default function setRequestInterceptors(axiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    let authToken = AuthService.getSystemToken();
    let authSellerToken = AuthService.getSellerToken();
    let authCustomerToken = AuthService.getCustomerToken();

    if (typeof authToken !== "undefined") {
      config.headers.Authorization = authToken;
    }

    if (typeof authSellerToken !== "undefined") {
      config.headers.AuthorizationSaleOfServices = authSellerToken;
    }

    if (typeof authCustomerToken !== "undefined") {
      config.headers.AuthorizationCustomer = authCustomerToken;
    }
    return config;
  });
}
