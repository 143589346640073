import Api from "@/services/api";

export async function darkWebEmailScan(email) {
  const apiUrl = "api/sale-of-services/mcafee/darkweb-email-scan";
  try {
    const response = await Api.post(apiUrl, { email });
    return response;
  } catch (error) {
    console.error("Erro na chamada darkWeb:", error);
    return null;  
  }
}

export default {
  darkWebEmailScan
};
