import AuthService from '../services/auth.service'
import Configuration from '@/services/configuration'
import UserStore from './user'
import OrderService from '@/modules/customer/services/orders.service'

const authStore = {
    namespaced: true,
    state: {
        authenticationInProgress: false,   
        authorizationCustomer: false,
        ordersCustomer: false,    
    },
    mutations: {
        SET_AUTHENTICATION_IN_PROGRESS (state, inProgress) {
            state.authenticationInProgress = inProgress
        },
        SET_AUTHENTICATE_USER (state, authorizationCustomer) {
            state.authorizationCustomer = authorizationCustomer
        },
        SET_ORDERS_CUSTOMER (state, ordersCustomer) {
          state.ordersCustomer = ordersCustomer
      },
    },
       
    actions: {
        async authenticate ({ commit, getters }) {
            if (getters.isAuthenticationInProgress) {
                return Promise.reject('inProgress')
            }
            commit('SET_AUTHENTICATION_IN_PROGRESS', true)

            return AuthService.signin(Configuration.api.seller.login, Configuration.api.seller.password)
                .finally(() => {
                    commit('SET_AUTHENTICATION_IN_PROGRESS', false)
                })
        },  
        async authenticateUser ({ commit, getters }, { email, password }) {
            if (getters.authorizationCustomer) {
                return Promise.reject('inProgress')
            }
            commit('SET_AUTHENTICATE_USER', true)

            return AuthService.customerSignin(email, password)
                .finally(() => {
                    commit('SET_AUTHENTICATE_USER', true)
                })
        },
        async myOrders ({ commit, getters }, { currentPage }) {
            if (getters.ordersCustomer) {
                return Promise.reject('inProgress')
            }
            commit('SET_ORDERS_CUSTOMER', true)

            return OrderService.myOrders(currentPage)
                .finally(() => {
                    commit('SET_ORDERS_CUSTOMER', true)
                })
        },

    },
    getters: {
        isAuthenticatedUser () {
            return typeof AuthService.getSystemToken() !== 'undefined' && typeof AuthService.getSellerToken() !== 'undefined'
        },
        AuthorizationCustomer () {
            return typeof AuthService.getCustomerToken() !== 'undefined' 
        },
       
        isAuthenticationInProgress: (state) => state.authenticationInProgress,
        isAuthorizationCustomer: (state) => state.authorizationCustomer,
        isOrdersCustomer: (state) => state.ordersCustomer,
    },
    modules: {
        user: UserStore,
    },
}

export default authStore;
